import React from "react";
import { PieChartOutlined, UserOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { LuBookPlus } from "react-icons/lu";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Dashboard", "", <PieChartOutlined />),
  getItem("User Management", "usermanagement", <UserOutlined />, [
    getItem("Menu", "menu"),
    getItem("Rights", "rights"),
    getItem("User Type", "usertype"),
    getItem("User", "user"),
  ]),
  getItem("Masters", "masters", <UserOutlined />, [
    getItem("Country", "country"),
    getItem("State", "state"),
    getItem("City", "city"),
    getItem("Company", "company"),
    getItem("Hotel", "hotel"),
    getItem("Region", "region"),
    getItem("Speciality Type", "specialitytype"),
    getItem("Tour Category", "tourcategory"),
    getItem("Meal Type", "mealtype"),
    getItem("Food Option", "foodoption"),
    getItem("GST", "gst"),
    getItem("Vendor Type", "vendortype"),
    getItem("Sector", "sector"),
    getItem("Nationality", "nationality"),
    getItem("Vehicle Type", "vehicletype"),
    getItem("Sightseen", "sightseen"),
    getItem("Itinerary", "itinerary"),
    getItem("Vendor", "vendors"),
    getItem("Document", "document"),
    getItem("Currency", "currency"),
    getItem("Airline", "airline"),
    getItem("Airport", "airport"),
    getItem("Airline Class", "airlineclass"),
    getItem("Vehicle Sharing Pax", "vehiclesharingpax"),
    getItem("Flight Place", "flightplace"),
    getItem("Pax Type", "paxtype"),
    getItem("Route", "route"),
    getItem("Branch", "branch"),
    getItem("Bank", "bank"),
    getItem("Room Category", "roomcategory"),
    // getItem("Hotel Mapping", "hotelmapping"),
  ]),
  getItem("Add Tour", "tour", <PieChartOutlined />),
  getItem("Booking Form", "tourbooking", <LuBookPlus />),
];

interface HeaderProps {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

  const userResponseString = localStorage.getItem("UserRightsAssign") || "[]";
  let userResponse: {
    MenuID: number;
    MenuKey: string;
    MenuName: string;
    RightsID: string;
  }[];

  try {
    userResponse = JSON.parse(userResponseString);
  } catch (error) {
    userResponse = [];
  }

  const allowedKeys = new Set(userResponse.map((item) => item.MenuKey));

  function filterMenuItems(menuItems: MenuItem[]): MenuItem[] {
    return menuItems.reduce((filteredItems, item: any) => {
      if (allowedKeys.has(item.key)) {
        if (item.children) {
          const filteredChildren = filterMenuItems(item.children);
          filteredItems.push({ ...item, children: filteredChildren });
        } else {
          filteredItems.push(item);
        }
      } else if (item.children) {
        const filteredChildren = filterMenuItems(item.children);
        if (filteredChildren.length > 0) {
          filteredItems.push({ ...item, children: filteredChildren });
        }
      }
      return filteredItems;
    }, [] as MenuItem[]);
  }

  let filteredItems = filterMenuItems(items);

  const hasDashboardAccess = userResponse.some(
    (item) => item.MenuKey === "dashboard"
  );
  if (hasDashboardAccess) {
    filteredItems = [
      getItem("Dashboard", "", <PieChartOutlined />),
      ...filteredItems,
    ];
  }

  function mapToGetItemFormat(menuItems: MenuItem[]): MenuItem[] {
    return menuItems.map((item: any) =>
      getItem(
        item.label,
        item.key,
        item.icon,
        item.children ? mapToGetItemFormat(item.children) : undefined
      )
    );
  }

  const formattedItems = mapToGetItemFormat(filteredItems);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="drawer-logo">
        <h3 style={{ textAlign: "center", color: "white", margin: 0 }}>AMT</h3>
      </div>
      <Menu
        className="sider-scroll"
        style={{
          overflow: "auto",
          height: "calc(100vh - 115px)",
        }}
        onClick={onClick}
        theme="dark"
        defaultSelectedKeys={[""]}
        selectedKeys={[location.pathname?.substring(1)?.split("/")[0]]}
        mode="inline"
        items={formattedItems}
        // items={items}
      />
    </Sider>
  );
};

export default Header;
